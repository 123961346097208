import {
  Card,
  Box,
  styled,
  Typography,
  CardMedia,
  Grid,
  Stack,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { fr } from "date-fns/locale";

const EventCard = ({
  img,
  price,
  name,
  eventID,
  debut,
  fin,
  city,
  lieu,
  type,
  organisateur,
}) => {
  const EventBox = styled(Card)(({ theme }) => ({
    borderRadius: "7px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "2px 2px 18px #dcdcde",
    },
    backgroundColor: "#fff",
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 0, 0),
    },
  }));

  const ImgContainer = styled(Box)(() => ({
    //width: "100%",
    //height:"200"
  }));
  const navigate = useNavigate();
  const handleTimestamp = (e) => {
    if (e) {
      const date = e.toDate();
      const tmp = date.toJSON().split(".");
      return tmp[0];
    }
    else{
      return '24/02/2013'
    }
  };

  return (
    <EventBox
      onClick={() => {
        navigate(`/fr/event/${eventID}`);
      }}
    >
      <ImgContainer>
        <CardMedia
          component="img"
          height="200"
          image={img}
          alt="green iguana"
        />
      </ImgContainer>

      <Box sx={{ paddingY: "1rem", paddingX: "1rem", height:{xs :'100%', sm: 200}}}>
        <Typography fontSize={18} variant="body2" sx={{ fontWeight: "700" }}>
          {name}
        </Typography>
        <Stack direction="column" spacing={1}>
          <Typography fontSize={14} fontWeight="550" color="#999999">
            {organisateur}
          </Typography>

          <Stack direction="row" spacing={1}>
            <Typography color='#5E17EB' fontSize={16}>
              {" "}

              {debut && format(new Date(handleTimestamp(debut)), 'dd MMM ', {locale : fr})} {debut && format(new Date(handleTimestamp(debut)), 'hh', {locale : fr})}h{debut && format(new Date(handleTimestamp(debut)), 'mm', {locale : fr})}
            </Typography>
            <Typography fontSize={16}> 2000 CFA </Typography>
          </Stack>
          <Typography fontSize={14}>{city} - {lieu}</Typography>
          <Grid item xs={3} sm={3}>
            <Box
              sx={{
                border: " 1px solid black",
                borderRadius: "20px",
                bgcolor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack direction="row">
                <Typography variant="caption" padding={0.5} color="white">
                  {type}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Stack>
      </Box>
    </EventBox>
  );
};

export default EventCard;
