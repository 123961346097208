import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MobileStepper,
  Paper,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { db } from "../../firebase.config";
import SubHero from "../subHero";
import Footer from "../Footer";
import Iconify from "../Iconify";

const Cgp = () => {
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const [city, setCity] = React.useState("Abidjan");
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const today = new Date();
        setLoading(true);
        setEvents([]);

        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),

          where("city", "==", city),
          orderBy("dateDebut", "asc"),
          limit(3)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.empty()
        // console.log(querySnapshot);
        // setEvents(querySnapshot.docs);
        querySnapshot.forEach(async (d) => {
          // get event organisateur

          const querySnapshot1 = await getDoc(
            doc(db, "organisations", d.data().orgId)
          );

          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [city]);

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 3;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <SubHero title="Politique de confidentialité" />{" "}
      <Box sx={{ py: 2 }}>
        <Box sx={{}}></Box>
        <Container>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Stack spacing={2}>
                <Typography variant="body1">
                  La présente politique relative aux données personnelles (la «
                  Politique ») a été rédigée par la société Choptonticket,
                  enregistrée au registre du commerce et des sociétés en Côte
                  d'Ivoire. Choptonticket est une entreprise de billetterie en
                  ligne fournissant des services de mise en relation entre des
                  organisateurs d'évènements et des clients souhaitant acheter
                  des billets. La Politique a pour objet de décrire les
                  traitements de données à caractère personnel réalisés par
                  Choptonticket pour remplir son obligation d'information en
                  tant que responsable de traitement conformément à la
                  législation en vigueur.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  0.APPLICATION DE LA POLITIQUE
                </Typography>
                <Typography variant="body1">
               Cette politique de confidentialité s'applique aux systèmes, opérations et processus de Choptonticket qui impliquent la collecte, l'utilisation, le stockage, le traitement et la divulgation d'informations personnelles. Cette politique de confidentialité s'applique généralement aux activités de Tuzzo et sert à définir notre approche en matière de données personnelles, y compris les données personnelles de nos Utilisateurs.<br /><br/>

En accédant ou en utilisant l'un de nos services, vous acceptez cette politique de confidentialité. <br/><br/>

Cette politique de confidentialité ne s'applique pas aux services qui ne sont pas détenus ou contrôlés par Choptonticket, y compris les sites Web tiers et les services des marchands de Tuzzo.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  1.DÉFINITIONS ET VOCABULAIRE UTILISÉ
                </Typography>
                <Typography variant="body1">
                  Choptonticket réalise différents traitements de données à
                  caractère personnel dans le cadre de ses activités. Ces
                  traitements, leurs finalités et leurs bases légales sont
                  décrits comme suit :
                  <List>
                  <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="« Application » :  désigne l’application mobile « Shotgun » à destination des Clients." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="« Application Organisateur » : désigne l'application mobile « CTT Scan » à destination des Organisateurs." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="« Plateforme » : désigne d'une part, l'ensemble du Site et de l'Application pour les Clients et d'autres part, le Site et l'application Organisateur pour les Organisateurs." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="« Client » : désigne la personne physique majeure achetant un billet par le biais de la Plateforme à titre non professionnel." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="« Organisateur » : désigne la personne physique ou morale organisant un évènement et utilisant la Plateforme pour la vente de billets." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="« Évènement » : désigne tout festival, concert, représentation scénique ou musicale géré par l'Organisateur par l'intermédiaire de la Plateforme." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="« Données à caractère personnel » : désigne toute information relative à une personne physique identifiée ou identifiable." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="« Politique » : désigne la présente politique de confidentialité." />
                    </ListItem>
                  </List>
                </Typography>

                <Typography paddingTop={3} variant="h6">
                  2.LES TRAITEMENTS, LEURS FINALITÉS ET LEURS BASES LÉGALES
                </Typography>
                <Typography variant="body1">
                  Choptonticket réalise différents traitements de données à
                  caractère personnel dans le cadre de ses activités. Ces
                  traitements, leurs finalités et leurs bases légales sont
                  décrits comme suit :
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>N°</TableCell>
                        <TableCell align="center">Traitement</TableCell>
                        <TableCell align="center">Finalité</TableCell>
                        <TableCell align="center">Base légale</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hover>
                        <TableCell>
                          1
                        </TableCell>
                        <TableCell>
                          Prospection et
                          analyse des
                          Visiteurs
                        </TableCell>
                        Identifier les Visiteurs du Site notamment
                        dans un but marketing et de mise à
                        disposition des contenus les plus adaptés
                        suivant l’activité des Visiteurs
                        <TableCell>
                          Consentement
                        </TableCell>

                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          2
                        </TableCell>
                        <TableCell>
                          Création de
                          Comptes
                        </TableCell>
                        Créer les Comptes afin que les Utilisateurs
                        puissent utiliser la Plateforme
                        <TableCell>
                          Exécution du Contrat
                        </TableCell>

                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          3
                        </TableCell>
                        <TableCell>
                          Connexion des utilisateurs
                        </TableCell>
                        Identifier les Utilisateurs lorsqu’ils
                        utilisent leurs Comptes et leur permettre
                        de retrouver les informations liées à leurs
                        Comptes
                        <TableCell>
                          Exécution du Contrat
                        </TableCell>

                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          4
                        </TableCell>
                        <TableCell>
                          Gestion des client
                        </TableCell>
                        Suivre l’exécution des commandes,
                        inscription sur liste d’attente, l’achat de Billets et l’envoi de Billets
                        <TableCell>
                          Exécution du Contrat
                        </TableCell>

                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          5
                        </TableCell>
                        <TableCell>
                          Paiement
                        </TableCell>
                        Permettre le traitement des paiements et la gestion des incidents de paiement
                        <TableCell>
                          Exécution du Contrat
                        </TableCell>

                      </TableRow>

                      <TableRow hover>
                        <TableCell>
                          6
                        </TableCell>
                        <TableCell>
                          Facturation & comptabilité
                        </TableCell>
                        Permettre à Choptonticket d’établir sa
                        comptabilité et de présenter les
                        justificatifs afférents en cas de demande
                        (notamment factures)
                        <TableCell>
                          Exécution du Contrat
                        </TableCell>

                      </TableRow>

                      <TableRow hover>
                        <TableCell>
                          7
                        </TableCell>
                        <TableCell>
                          Prospection et
                          relation
                          commerciale
                        </TableCell>
                        Développer la relation commerciale et
                        informer les Clients et les Organisateurs
                        des évolutions des offres de notre service
                        <TableCell>
                          Intérêt légitime à exploiter
                          ces données
                        </TableCell>

                      </TableRow>

                      <TableRow hover>
                        <TableCell>
                          8
                        </TableCell>
                        <TableCell>
                          Contentieux
                        </TableCell>
                        Permettre à Choptonticket d’organiser sa
                        défense en cas de contentieux ou
                        précontentieux
                        <TableCell>
                          Intérêt légitime à se
                          défendre
                        </TableCell>

                      </TableRow>

                      <TableRow hover>
                        <TableCell>
                          9
                        </TableCell>
                        <TableCell>
                        Fraude
                        </TableCell>
                        Empêcher la fraude et toutes escroqueries 
pouvant viser les Clients de Shotgun et les 
Utilisateurs et Administrateurs de la 
Solution, notamment, mais pas seulement, 
l’utilisation de cartes bancaires volées
                        <TableCell>
                          Intérêt légitime à empêcher la fraude
                        </TableCell>

                      </TableRow>

                    </TableBody>

                  </Table>
                </TableContainer>

                <Typography paddingTop={3} variant="h6">
                  3.RÔLE DE CHOPTONTICKET ET RESPONSABILITÉS
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  3.1.Choptonticket en tant que responsable de traitement
                </Typography>
                <Typography variant="body1">
                  Choptonticket est responsable du traitement des données à
                  caractère personnel suivantes :
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Informations générales sur les Utilisateurs (identifiants, noms, prénoms, date de naissance, mots de passe, adresses email, numéros de téléphone)." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Données de connexion des Personnes Concernées sur la Plateforme (identifiants de connexion, mots de passe protégés, heures de connexion, adresses IP, localisations, historiques des données de connexion, numéros uniques associés à des cookies uniques)" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Profil du Client" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Profil du Organisateur (types d’Évènements organisés) " />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Données d’achat des Clients (nom et date de l’Évènement, nombre et prix des Billets achetés, 
adresse email pour envoi du Billet) ;"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Données de paiement et de facturation (adresse de facturation, moyen de paiement, historique des transaction) ;" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Données de satisfaction et de réponses à des enquêtes par les Personnes Concernées si elles ont 
accepté le partage d’information (satisfaction, commentaire, interrogation concernant un 
Évènement ou l’usage de la Plateforme) ;"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Autres données à caractère personnel des Personnes Concernées échangées lors des 
réclamations et interactions avec le support client Shotgun (données relatives au tracking de 
sessions Utilisateurs, problèmes rencontrés)."
                      />
                    </ListItem>
                  </List>
                  Pour toute question relative au traitement de leurs données à
                  caractère personnel ou pour l'exercice de leurs droits, les
                  Personnes Concernées peuvent contacter Choptonticket via les
                  coordonnées fournies dans la Politique.
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  3.2.Choptonticket en tant que sous-traitant
                </Typography>
                <Typography variant="body1">
                  Choptonticket agit également en tant que sous-traitant pour
                  les données à caractère personnel collectées pour les
                  Organisateurs et/ou ses Clients. Les informations, notamment
                  les bases de données clients, sont traitées conformément aux
                  stipulations contractuelles définies dans le Contrat signé
                  entre Choptonticket et l'Organisateur.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  4.DESTINATAIRE DES DONNÉES À CARACTÈRE PERSONNEL TRAITÉES
                </Typography>
                <Typography variant="body1">
                  Les données à caractère personnel collectées sont destinées
                  aux services internes de Choptonticket ainsi qu'à ses
                  sous-traitants. Les catégories de sous-traitants peuvent
                  inclure ceux qui fournissent des solutions numériques pour les
                  opérations quotidiennes, la maintenance, le marketing et la
                  communication, les services financiers, etc.
                  <br /><br />
                  Ni Choptonticket ni ses sous-traitants ne commercialisent les
                  données à caractère personnel des Personnes Concernées.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  5.LA CONSERVATION DES DONNÉES À CARACTÈRE PERSONNEL
                </Typography>
                <Typography variant="body1">
                  Les données des Personnes Concernées sont conservées pendant
                  toute la durée de la relation avec Choptonticket, afin de
                  permettre l'atteinte des finalités décrites dans la Politique
                  et de respecter les obligations légales.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  6.LES DROITS DES PERSONNES CONCERNÉES
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  6.1.La nature des droits
                </Typography>
                <Typography variant="body1">
                  Conformément à la réglementation, les Personnes Concernées
                  bénéficient des droits suivants :
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Droit d'accès : obtenir une copie des données à caractère personnel détenues par Choptonticket." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Droit de rectification et d'effacement : demander la rectification des données erronées ou leur suppression." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Droit d'opposition et à la limitation du traitement : s'opposer au traitement ou demander sa limitation." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Droit à la portabilité : demander l'envoi des données à un autre responsable de traitement." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Droit à la suppression : demander la suppression de toutes ses informations presentes sur toutes nos plateformes." />
                    </ListItem>
                  </List>
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  6.2.L'exercice des droits
                </Typography>
                <Typography variant="body1">
                  L'exercice de ces droits est soumis à des conditions et peut
                  être effectué en contactant Choptonticket via les coordonnées
                  fournies dans la Politique.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  7.LES TRANSFERTS DE DONNÉES
                </Typography>
                <Typography variant="body1">
                  Choptonticket peut transférer des données à caractère
                  personnel en dehors de la Côte d'Ivoire. Si ces transferts ont
                  lieu vers des pays ne bénéficiant pas d'une décision
                  d'adéquation, Choptonticket s'assure que des mesures
                  juridiques, techniques et organisationnelles appropriées sont
                  mises en place.
                  <br /><br />
                  Les Personnes Concernées peuvent obtenir une copie des données
                  transférées en contactant Choptonticket via les coordonnées
                  fournies dans la Politique.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  8.COOKIES
                </Typography>
                <Typography variant="body1">
                  Choptonticket utilise des cookies pour mesurer l'activité et
                  la fréquentation de son site web, personnaliser l'interface
                  utilisateur et enregistrer les préférences linguistiques des
                  visiteurs. Les personnes concernées peuvent retirer leur
                  consentement à l'utilisation de ces traceurs depuis le site de
                  Choptonticket.
                  <br /><br />
                  Nous utilisons le types de cookies suivants sur notre
                  plateforme :
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Cookies fonctionnels : Nous les utilisons pour mémoriser toutes les sélections que vous effectuez sur notre plateforme afin qu'elles soient enregistrées pour vos futures visites." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Cookies analytiques : Cela nous permet d'améliorer la conception et la fonctionnalité de notre plateforme en collectant des données sur le contenu auquel vous accédez et avec lequel vous interagissez lorsque vous utilisez notre plateforme." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="icon-park:dot" />
                      </ListItemIcon>
                      <ListItemText primary="Cookies de ciblage : Cela nous permet d'améliorer la conception et la fonctionnalité de notre plateforme en collectant des données sur le contenu auquel vous accédez et avec lequel vous interagissez lorsque vous utilisez notre plateforme." />
                    </ListItem>
                  </List>
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  9.LA SÉCURITÉ DES DONNÉES
                </Typography>
                <Typography variant="body1">
                  Choptonticket met en œuvre des mesures techniques,
                  organisationnelles et administratives pour protéger les
                  données à caractère personnel contre la perte, le vol, l'accès
                  non autorisé, la divulgation, l'altération et la destruction.
                  <br />
                  <br />
                  Pour nous assurer que votre sécurité est protégée, nous
                  utilisons le protocole de sécurité de la couche de transport
                  pour transmettre des informations personnelles via notre
                  système.
                  <br />
                  <br />
                  Toutes les données stockées dans notre système sont bien
                  sécurisées et ne sont accessibles qu'à nos employés. Nos
                  employés sont liés par des accords de confidentialité stricts
                  et une violation de cet accord entraînera le licenciement de
                  l'employé.
                  <br />
                  <br />
                  Bien que nous prenions toutes les précautions raisonnables
                  pour nous assurer que nos données d'utilisateur soient
                  sécurisées et que les utilisateurs soient protégés, il reste
                  toujours un risque de préjudice. L'Internet dans son ensemble
                  peut parfois être peu sûr et nous ne sommes donc pas en mesure
                  de garantir la sécurité des données des utilisateurs au-delà
                  de ce qui est raisonnablement pratique.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  10.LA MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ
                </Typography>
                <Typography variant="body1">
                  Cette politique de confidentialité peut être modifiée à
                  l'occasion afin de maintenir la conformité avec la loi et de
                  tenir compte de tout changement à notre processus de collecte
                  de données. Nous recommandons à nos utilisateurs de vérifier
                  notre politique de temps à autre pour s'assurer qu'ils soient
                  informés de toute mise à jour. Au besoin, nous pouvons
                  informer les utilisateurs par e-mail des changements apportés
                  à cette politique.
                </Typography>
                <Typography paddingTop={3} variant="h6">
                  11.CONTACT
                </Typography>
                <Typography variant="body1">
                Si vous avez des questions, des commentaires, des plaintes ou des préoccupations concernant vos informations personnelles ou cette politique de confidentialité, veuillez nous contacter par courrier à notre adresse opérationnelle : support@choptonticket.com ou via le support Choptonticket.
                <br /><br />
                Dernière mise à jour de la Politique : 13/02/2024.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              marginTop: 5,
              padding: 3,
              borderRadius: "10px",
              bgcolor: "black",
              color: "white",
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                align="left"
                sx={{
                  fontSize: "20px",
                  fontWeight: "800",
                }}
              >
                Il vous reste des questions ?
              </Typography>
              <Typography align="left">
                Notre équipe se fera un plaisir de vous aider
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  window.open("https://api.whatsapp.com/send?phone=0022509219343");
                }}
                size="large"
                sx={{
                  textTransform: "initial",
                  border: "2px solid transparent",
                  color: "black",
                  fontWeight: 600,
                  backgroundColor: "white",
                  borderRadius: "7px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    borderColor: "black",
                  },
                }}
                variant="contained"
              >
                Contactez-nous
              </Button>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Cgp;
