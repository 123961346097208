import { Divider, Grid, Link, Stack, styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";

import fbIcon from "../media/fbicon.png";
import twitterIcon from "../media/twittericon.png";
import linkedinIcon from "../media/linkedinicon.png";
import Iconify from "./Iconify";
import logo from "../media/logo2.png";

const Footer = () => {
  const CustomContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    gap: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const IconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  }));

  const FooterLink = styled("span")(({ theme }) => ({
    fontSize: "16px",
    color: "#7A7A7E",
    fontWeight: "300",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
    },
  }));
  const NavbarLogo = styled("img")(({ theme }) => ({
    cursor: "pointer",
    width: 100,
  }));

  return (
    <Box sx={{ py: 10, px:5, display:'flex', justifyContent:'center', position:'relative',bottom:0, zIndex:-1 }}>
      
      <Grid spacing={5} container textAlign="center" justifyContent="space-around">
          <Grid item xs={12}>
            <Box width='100%' height={3} sx={{background: "linear-gradient(0.25turn, #e66465, #9198e5)"}}>

            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Link href="/fr/" underline="none">
              <NavbarLogo src={logo} alt="logo" />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography colors="white" fontSize={16} textAlign="center" variant="body1">
              Besoin d'aide, de conseils ou d'informations ? Notre équipe est à votre disposition du lundi au samedi, de
              9h à 18h
            </Typography>
            <Typography paddingY={2} colors="white" fontSize={16} textAlign="center" variant="body1">
              Suivez nous les nos diferentes plateformes :
            </Typography>
            <Stack spacing={2} direction="row" display='flex' alignItems='center' justifyContent='center'>
              <Iconify icon="akar-icons:facebook-fill" width={30} height={30} />
              <Iconify icon="akar-icons:instagram-fill" width={30} height={30} />
              <Iconify icon="akar-icons:instagram-fill" width={30} height={30} />
            </Stack>
            
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography paddingBottom={{xs:6, sm:0}} colors="white" fontSize={16} textAlign="center" variant="body1">
            2023 Choptonticket, Tous droits reservés.
            Made by Choptonticket. 
            </Typography>
          </Grid>
          
        </Grid>
    </Box>
  );
};

export default Footer;