import {
  Button,
  Card,
  CardMedia,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useCallback } from "react";
import { useMemo } from "react";
import CustomButton from "../CustomButton";
import Iconify from "../Iconify";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PdfTicket from "../pdf/pdfTicket";
import { useEffect } from "react";
import { useState } from "react";
var QRCode = require("qrcode");
const events = [
  { nom: "dd", lat: 50, lng: 56 },
  { nom: "d2", lat: 20, lng: 56 },
  { nom: "d3", lat: 20, lng: 5659 },
  { nom: "d4", lat: 2590, lng: 56 },
  { nom: "d5", lat: 77, lng: 56 },
];

export default function Ticket({ img, info }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const center = useMemo(() => ({ lat: 44, lng: -90 }), []);
  const [qrcode, setQr] = useState('')
  useEffect(() => {
    (async () => {
      try {
        QRCode.toDataURL(info.billet.numero, {
          width: 130,
          margin: 1,
          errorCorrectionLevel: 'H',
          color: {
            dark: '#000000',
            light:'#ffffff'
          }
        },(err, url) => {
          if(err) return console.log('erreur : ', err)
          setQr(url)
        }) 
      } catch (error) {
        console.log(error);
      }
    })();
  }, []); 
  if (!isLoaded)
    return <Skeleton variant="rounded" width={`100%`} height={250} />;
  return (
    <Card sx={{ marginY: 2 }}>
      <Grid padding={3} container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card sx={{ maxWidth: 500 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={info.event.idVisuel}
              title="green iguana"
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
            }}
          >
            {info.event.nomEvent} - {info.billet.nom}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: "orange",
              fontWeight: "500",
            }}
          >
            sam 7 janv. 21:00 - dim 8 janv. 02:00
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            {info.event.lieu_event} , {info.event.city}, {info.event.country}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#ccc",
              fontWeight: "500",
              mb: 3,
            }}
          >
            Commande N° {info.billet.refCommande}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PDFDownloadLink
            document={<PdfTicket info={info} qr={qrcode} />}
            fileName={`${info.billet.refCommande}.pdf`}
          >
            {({ loading, error }) => {
              return loading ? (
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    textTransform: "initial",
                    border: "2px solid #5E17EB",
                    backgroundColor: "#5E17EB",
                    color: "white",
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#5E17EB",
                    },
                  }}
                  variant="contained"
                  onClick={() => {}}
                >
                  <Stack
                    spacing={1}
                    direction="row"
                    display="flex"
                    alignItems="center"
                  >
                    <Iconify
                      icon="material-symbols:download"
                      sx={{ height: 24, width: 24 }}
                    />
                    <Typography fontSize={16}>Telecharger</Typography>{" "}
                  </Stack>
                </Button>
              ) : (
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    textTransform: "initial",
                    border: "2px solid #5E17EB",
                    backgroundColor: "#5E17EB",
                    color: "white",
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#5E17EB",
                    },
                  }}
                  variant="contained"
                  onClick={() => {}}
                >
                  <Stack
                    spacing={1}
                    direction="row"
                    display="flex"
                    alignItems="center"
                  >
                    <Iconify
                      icon="material-symbols:download"
                      sx={{ height: 24, width: 24 }}
                    />
                    <Typography fontSize={16}>Telecharger</Typography>{" "}
                  </Stack>
                </Button>
              );
            }}
          </PDFDownloadLink>
        </Grid>
      </Grid>
    </Card>
  );
}
