import { Navigate, useParams, useRoutes } from "react-router-dom";
import ProtectedRoute from "./Components/protectedRoute";
// layouts

//

import Layout from "./layout/layout";
import EventPage from "./pages/eventPage";
import Home from "./pages/home";
import Profile from "./pages/profile";
import SearchPage from "./pages/searchPage";
import Confirmation from "./pages/confirmation";
import Organisateur from "./pages/organisateur";
import Categorie from "./Components/categorie";
import Article from "./Components/article";
import Cgp from "./Components/legacy/cgp";
import Cgu from "./Components/legacy/cgu";

// ----------------------------------------------------------------------

export default function Router() {
  const params = useParams();
  return useRoutes([
    {
      path: "/",
      element: <Layout />,

      children: [
        { path: "/", element: <Navigate to="/fr" /> },
        { path: "fr", element: <Home /> },
        {
          path: "fr/profile",
          element: (
            <ProtectedRoute>
              <Profile />,
            </ProtectedRoute>
          ),
        },
        { path: "fr/event/:eventID", element: <EventPage /> },
        { path: "fr/organisateur/:orgID", element: <Organisateur /> },
        { path: "fr/checkout/:confirmID/confirmation", element: <Confirmation /> },
        { path: "fr/categories/:IDcategorie", element: <Categorie /> },
        { path: "fr/categories/:IDcategorie/articles/:IDarticles", element: <Article /> },
        { path: "fr/categories/legacy/articles/cgp", element: <Cgp /> },
        { path: "fr/categories/legacy/articles/cgu", element: <Cgu /> },
        { path: "fr/requests/new", element: <EventPage /> },
        { path: "privacy", element: <Cgp /> },
      ],
    },
    {
      path: "/",
      element: <Navigate to="/fr" replace />,
    },
  ]);
}
