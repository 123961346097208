import {
  Box,
  Button,
  Container,
  Grid,
  MobileStepper,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import House from "./EventCard";
import { properties } from "../properties";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";
import EventCard from "./EventCard";
import Iconify from "./Iconify";
import useResponsive from "./hooks/useResponsive";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import CustomCard from "./CustomCard";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Part1 = () => {
  const PropertiesBox = styled(Box)(({ theme }) => ({
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  const PropertiesTextBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const [city, setCity] = React.useState("Abidjan");
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const today = new Date();
        setLoading(true);
        setEvents([]);

        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),

          where("city", "==", city),
          orderBy("dateDebut", "asc"),
          limit(3)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.empty()
        // console.log(querySnapshot);
        // setEvents(querySnapshot.docs);
        querySnapshot.forEach(async (d) => {
          // get event organisateur

          const querySnapshot1 = await getDoc(
            doc(db, "organisations", d.data().orgId)
          );

          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [city]);

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 3;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ py: 2 }}>
      <Container>
        <Grid container rowSpacing={5} paddingY={2}>
          <Grid item xs={12} md={4} sx={{ height: `100%` }}>
            <CustomCard
              icon="solar:question-circle-linear"
              title="FAQ"
              desc="Toutes les réponses à vos questions les plus fréquentes"
              sx={{ height: `100%` }}
            />
          </Grid>
        </Grid>
        <Typography
          align="left"
          sx={{
            paddingTop: 5,
            paddingX: 2,
            fontSize: "33px",
            fontWeight: "800",
          }}
        >
          Besoin d'aides
        </Typography>
        <Grid container rowSpacing={5} paddingY={2}>
          <Grid item xs={12} md={4} sx={{ height: `100%` }}>
            <CustomCard
            icon="iconamoon:profile-light"
            ID='compte'
              title="Compte"
              desc="Tout ce qui concerne ton compte utilisateur"
              sx={{ height: `100%` }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: `100%` }}>
            <CustomCard
            icon="game-icons:sell-card"
              title="Revente"
              desc="En savoir plus sur la revente ou le transfert de billets"
              sx={{ height: `100%` }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: `100%` }}>
            <CustomCard
            icon="ph:ticket-thin"
              title="Commandes et paiements"
              desc="Trouve de téponses à tes questions concernant les commandes, checkout et les remboursements"
              sx={{ height: `100%` }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: `100%` }}>
            <CustomCard
            icon="carbon:event"
              title="Evènements"
              desc="Tes questions à propose de soucis ou logistiques de l'événement"
              sx={{ height: `100%` }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: `100%` }}>
            <CustomCard
            icon='iconamoon:discount-thin'
              title="Codes promo"
              desc="Toutes les réponses à vos questions les plus fréquentes"
              sx={{ height: `100%` }}
            />
          </Grid>
        </Grid>
        <Typography
          align="left"
          sx={{
            paddingTop: 5,
            paddingX: 2,
            fontSize: "33px",
            fontWeight: "800",
          }}
        >
          Législation
        </Typography>
        <Grid container rowSpacing={5} paddingY={2}>
          <Grid item xs={12} md={4} sx={{ height: `100%`, }}>
            <CustomCard
            link='/fr/categories/legacy/articles/cgp'
            icon="material-symbols-light:shield-outline"
              title="Conditions de confidentialité"
              desc="En savoir plus sur la politique de confidentialié de Choptonticket"
              sx={{ height: `100%` }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: `100%` }}>
            <CustomCard
            link='/fr/categories/legacy/articles/cgu'
            icon="ph:book-open-text-thin"
              title="Conditions d'utilisations"
              desc="En savoir plus sur les conditions d'utilisations de Choptonticket"
              sx={{ height: `100%` }}
            />
          </Grid>
        </Grid>
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            marginTop:5,
            padding: 3,
            borderRadius: "10px",
            bgcolor: "black",
            color: "white",
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography
              align="left"
              sx={{
                fontSize: "20px",
                fontWeight: "800",
              }}
            >
              Il vous reste des questions ?
            </Typography>
            <Typography align="left">
              Notre équipe se fera un plaisir de vous aider
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={() => {
                window.open("https://api.whatsapp.com/send?phone=0022509219343");
              }}
              size="large"
              sx={{
                textTransform: "initial",
                border: "2px solid transparent",
                color: "black",
                fontWeight: 600,
                backgroundColor: "white",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                },
              }}
              variant="contained"
            >
              Contactez-nous
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Part1;
