import Companies from "../Components/Companies";
import Guide from "../Components/Guide";
import Hero from "../Components/Hero";
import Details from "../Components/Details";

import Footer from "../Components/Footer";
import Part1 from "../Components/part1";


function Home() {
  return (
    <>
        <Hero />
        <Part1/>
        <Footer />
    </>
  );
}

export default Home;
