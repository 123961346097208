import {
    Card,
    Box,
    styled,
    Typography,
    CardMedia,
    Grid,
    Stack,
  } from "@mui/material";
  import React from "react";
  import { useNavigate } from "react-router-dom";
  import { format } from 'date-fns';
  import { fr } from "date-fns/locale";
import Iconify from "./Iconify";
  
  const CustomCard = ({
    icon,
    title,
    name,
    ID,
    desc,
    link
  }) => {
    const EventBox = styled(Card)(({ theme }) => ({
      borderRadius: "7px",
      height: '100%',
      cursor: "pointer",
      "&:hover": {
        boxShadow: "2px 2px 18px #dcdcde",
      },
      backgroundColor: "#fff",
      margin: theme.spacing(0, 2, 0, 2),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(0, 0, 0, 0),
      },
    }));
  
    const ImgContainer = styled(Box)(() => ({
      //width: "100%",
      //height:"200"
    }));
    const navigate = useNavigate();
    const handleTimestamp = (e) => {
      if (e) {
        const date = e.toDate();
        const tmp = date.toJSON().split(".");
        return tmp[0];
      }
      else{
        return '24/02/2013'
      }
    };
  
    return (
      <EventBox
        onClick={() => {
          console.log(link)
          console.log(ID)
          if (ID != null) {
            navigate(`/fr/categories/${ID}`);
          }else if (link != null) {
            console.log('ok')
            navigate(`${link}`);
          }
          
        }}
      >
        <Box sx={{ paddingY: "1.5rem", paddingX: "1.5rem", height: '100%' }}>
            <Box>
                <Iconify icon={icon}/>
            </Box>
          <Typography fontSize={18} variant="body2" sx={{ paddingTop: 2, fontWeight: "700" }}>
            {title}
          </Typography>
          <Stack direction="column" spacing={1}>
            <Typography fontSize={14} color="#999999">
              {desc}
            </Typography>
          </Stack>
        </Box>
      </EventBox>
    );
  };
  
  export default CustomCard;
  