

import { AuthContextProvider } from "./context/AuthContext";
import Router from "./route";
import { VariableContextProvider } from "./context/VariableContext";

function App() {
  return (
    <>
      <AuthContextProvider>
        <VariableContextProvider>
          {/*<Hero />
        <Companies /> 
        <Guide />
        <Properties />
        <Details />
        <GetStarted />
        <Footer />*/}
          <Router />
        </VariableContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
