import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MobileStepper,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { db } from "../../firebase.config";
import SubHero from "../subHero";
import Footer from "../Footer";
import Iconify from "../Iconify";

const Cgu = () => {
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const [city, setCity] = React.useState("Abidjan");
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const today = new Date();
        setLoading(true);
        setEvents([]);

        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),

          where("city", "==", city),
          orderBy("dateDebut", "asc"),
          limit(3)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.empty()
        // console.log(querySnapshot);
        // setEvents(querySnapshot.docs);
        querySnapshot.forEach(async (d) => {
          // get event organisateur

          const querySnapshot1 = await getDoc(
            doc(db, "organisations", d.data().orgId)
          );

          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [city]);

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 3;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <SubHero title="CONDITIONS GÉNÉRALES D'UTILISATION" />{" "}
      <Box sx={{ py: 2 }}>
        <Box sx={{}}></Box>
        <Container>
          <Stack spacing={2}>
            <Typography variant="body1">
              Les présentes Conditions Générales d'Utilisation ont été rédigées
              par ChoptonTicket, une société [votre forme juridique],
              immatriculée au RCS de [votre ville] sous le numéro [votre numéro
              RCS]. ChoptonTicket est une entreprise technologique offrant une
              solution de billetterie en ligne, permettant la mise en relation
              entre des organisateurs d'évènements et des clients souhaitant
              découvrir et acheter des billets.
            </Typography>
            <Typography paddingTop={3} variant="h6">
              1.DÉFINITIONS
            </Typography>
            <Typography variant="body1">
              Les termes suivants ont la signification qui leur est attribuée
              dans l'intégralité des Conditions Générales d'Utilisation (CGU) et
              des Conditions Particulières (CP) :
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="Annexe : Fait référence à l'annexe 1 des présentes CGU." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="Annulation du Billet ou Revente : Désigne la destruction d'un Billet suite à la demande du Client, entraînant son remboursement selon les modalités spécifiées à la section 3.3 des CGU." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="« Organisateur » : désigne la personne physique ou morale organisant un évènement et utilisant la Plateforme pour la vente de billets." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="« Évènement » : désigne tout festival, concert, représentation scénique ou musicale géré par l'Organisateur par l'intermédiaire de la Plateforme." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="« Données à caractère personnel » : désigne toute information relative à une personne physique identifiée ou identifiable." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="« Politique » : désigne la présente politique de confidentialité." />
                </ListItem>
              </List>
            </Typography>
            <Typography paddingTop={3} variant="h6">
              2.DOCUMENTS CONTRACTUELS ET HIÉRARCHIE
            </Typography>
            <Typography variant="body1">
              La relation contractuelle entre ChoptonTicket et les Parties
              (Clients et Organisateurs) est définie par le Contrat, qui englobe
              les CGU et les CP. En cas de contradiction entre les documents
              contractuels, la hiérarchie prévaut comme suit : avenants ou
              contrats spécifiques, CP (du plus récent au plus ancien), CGU.
              Pour intégrer d'autres documents au contrat, un accord écrit entre
              les Parties est nécessaire, précisant leur articulation avec les
              documents existants.
            </Typography>
            <Typography paddingTop={3} variant="h6">
              3.FONCTIONNEMENT DE LA PLATEFORME ET ENGAGEMENTS DES UTILISATEURS
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              3.1.Fonctionnement de la Plateforme pour les Clients
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.1.1.Conditions de création de Compte Client
            </Typography>
            <Typography variant="body1">
              Pour acheter des Billets ou se connecter à l'Application, la
              création d'un Compte Client est nécessaire. Toute personne
              physique majeure non agissante à des fins professionnelles peut
              créer gratuitement un Compte Client en fournissant [les
              informations nécessaires]. L'acceptation des CGU s'effectue lors
              de l'inscription sur la Plateforme.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.1.2.Vente d'un Billet
            </Typography>
            <Typography variant="body1">
              Les Clients peuvent acheter des Billets via le Site ou
              l'Application. Tout au long du processus d'achat, le Client peut
              se connecter ou créer un Compte Client. Durant cette étape, les
              spécificités des Billets et de l'Évènement sont accessibles. En
              cas de liste d'attente pour un Évènement complet, le Client peut
              s'inscrire sur l'Application. De plus, l'option "Achat
              automatique" permet au Client d'acheter un Billet indifféremment
              de la catégorie, et, s'il est le premier sur la liste d'attente,
              d'acheter automatiquement le Billet ou d'être informé de sa
              disponibilité pendant 20 minutes.
              <br />
              Le prix affiché dans le panier correspond au Prix Total du Billet.
              Un contrôle 3D Secure peut être effectué par la banque du Client
              lors du paiement. Une fois l'achat confirmé, ChoptonTicket envoie
              par email au Client le reçu de paiement, le Billet en format PDF,
              et un lien vers la version numérique dans l'Application.
              ChoptonTicket n'émet pas de Billet physique.
              <br />
              En cas de commande groupée, le propriétaire du Billet est le
              Client initial jusqu'à ce qu'il transfère les Billets aux
              personnes de son choix via la Plateforme, devenant ainsi les
              porteurs légitimes. Bien que les Billets soient en principe
              personnels mais non nominatifs, certains Évènements peuvent exiger
              que le bénéficiaire soit indiqué dès l'achat.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.1.3.Responsabilité du Client
            </Typography>
            <Typography variant="body1">
              Le Client est seul responsable de son Compte Client et du Billet
              acheté. En achetant un Billet, le Client adhère aux conditions de
              l'Organisateur spécifiées sur le Site ou le Billet, comprenant que
              le Billet le lie contractuellement à l'Organisateur.
              <br />
              Le Client s'engage et garantit :
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De ne pas dupliquer le Billet." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De présenter le Billet au contrôle d'accès conformément aux règles spécifiées." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De présenter une pièce d'identité si demandé par l'Organisateur." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De ne pas acheter des Billets pour revente excessive, respectant les limites fixées par l'Organisateur." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De ne pas acheter des Billets pour revente excessive, respectant les limites fixées par l'Organisateur." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De ne pas acheter des Billets pour revente excessive, respectant les limites fixées par l'Organisateur." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De ne pas acheter des Billets pour revente excessive, respectant les limites fixées par l'Organisateur." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De ne pas acheter des Billets pour revente excessive, respectant les limites fixées par l'Organisateur." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="De ne pas acheter des Billets pour revente excessive, respectant les limites fixées par l'Organisateur." />
                </ListItem>
              </List>
              Tout manquement aux obligations du Client dans le cadre des CGU
              peut entraîner la suspension ou résiliation du Compte Client, et
              l'annulation des Billets associés. Cette disposition n'exclut
              aucun autre recours que ChoptonTicket pourrait entreprendre. En
              cas de revente frauduleuse de Billets en dehors de la Plateforme,
              ChoptonTicket se réserve le droit d'annuler le Billet vendu et de
              prendre des mesures nécessaires pour protéger les Clients contre
              de telles pratiques.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              3.2.Fonctionnement de la Plateforme pour les Organisateurs
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.2.1.Conditions de création du Compte Organisateur
            </Typography>
            <Typography variant="body1">
              Les personnes physiques ou morales souhaitant organiser des
              Évènements et vendre des Billets doivent créer un Compte
              Organisateur. L'inscription nécessite de fournir des informations
              précises et à jour. ChoptonTicket se réserve le droit de refuser
              ou suspendre un Compte Organisateur si les informations sont
              jugées inexactes ou trompeuses.
              <br />
              En créant un Compte Organisateur, l'Organisateur s'engage à :
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="Fournir des informations exactes et complètes, et à les mettre à jour régulièrement." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="Respecter les lois et réglementations applicables dans l'organisation d'Évènements et la vente de Billets." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="Accepter les CGU et les Conditions Particulières proposées par ChoptonTicket." />
                </ListItem>
              </List>
              L'Organisateur est seul responsable de la sécurité de son compte
              et de toutes les activités associées. Tout usage non autorisé du
              Compte Organisateur doit être signalé immédiatement à
              ChoptonTicket.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.2.2.Mise en Vente des Billets
            </Typography>
            <Typography variant="body1">
              L'Organisateur peut mettre en vente des Billets sur la Plateforme
              en définissant les spécificités de l'Évènement, les catégories de
              Billets, les prix, et d'autres détails pertinents. ChoptonTicket
              se réserve le droit de vérifier et approuver ces informations
              avant la mise en vente.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.2.3.Responsabilités de l'Organisateur
            </Typography>
            <Typography variant="body1">
              L'Organisateur s'engage à :
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="Respecter les lois et règlements relatifs à l'organisation d'Évènements et à la vente de Billets." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="Garantir la disponibilité des Billets mis en vente." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify icon="icon-park:dot" />
                  </ListItemIcon>
                  <ListItemText primary="Payer les Frais de Services à ChoptonTicket conformément aux Conditions Particulières." />
                </ListItem>
              </List>
              Toute violation de ces engagements peut entraîner la suspension ou
              la résiliation du Compte Organisateur et la suppression des
              Évènements associés.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              3.3.Annulation et Revente des Billets
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.3.1.Annulation par le Client
            </Typography>
            <Typography variant="body1">
              Un Client peut demander l'annulation d'un Billet dans les
              conditions spécifiées par ChoptonTicket. Les conditions
              d'annulation et les remboursements sont détaillés dans les CGU et
              les Conditions Particulières.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.3.2.Revente des Billets
            </Typography>
            <Typography variant="body1">
              ChoptonTicket peut proposer des services de revente de Billets
              sous certaines conditions. L'Organisateur accepte que la revente
              puisse entraîner des frais supplémentaires, détaillés dans les
              Conditions Particulières.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              3.4.Services et Engagements Financiers
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.4.1.Frais de Services
            </Typography>
            <Typography variant="body1">
              ChoptonTicket facturera des Frais de Services au Client pour
              l'utilisation de la Plateforme. Les détails concernant ces frais
              sont accessibles pendant le processus d'achat et sont détaillés
              dans les CGU et les Conditions Particulières.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.4.2.Commission pour les Organisateurs
            </Typography>
            <Typography variant="body1">
              ChoptonTicket peut percevoir une commission sur les ventes de
              Billets, définie dans les Conditions Particulières. L'Organisateur
              accepte ces commissions comme contrepartie des services fournis
              par ChoptonTicket.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.4.3.Paiements et Facturation
            </Typography>
            <Typography variant="body1">
              Les paiements liés à la vente de Billets et aux services de
              ChoptonTicket seront traités conformément aux modalités spécifiées
              dans les Conditions Particulières. L'Organisateur s'engage à
              fournir des informations financières précises et à respecter les
              échéances de paiement.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              3.4.4.Responsabilité Financière
            </Typography>
            <Typography variant="body1">
              ChoptonTicket et l'Organisateur reconnaissent que chacun est
              responsable de ses propres obligations financières. Tout litige
              financier entre les Parties sera résolu conformément aux
              dispositions des CGU et des Conditions Particulières.
            </Typography>
            <Typography paddingTop={3} variant="h6">
              4.PROPRIÉTÉ INTELLECTUELLE ET DONNÉES PERSONNELLES
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              4.1.Droits de Propriété Intellectuelle
            </Typography>
            <Typography variant="body1">
              ChoptonTicket détient les droits de propriété intellectuelle liés
              à la Plateforme. L'Utilisateur s'engage à respecter ces droits et
              ne pas reproduire, distribuer, ou exploiter de manière non
              autorisée le contenu de la Plateforme.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              4.2.Données Personnelles
            </Typography>
            <Typography variant="body1">
              Les Parties s'engagent à respecter les Règles sur les Données
              Personnelles définies dans les CGU. ChoptonTicket collecte,
              traite, et stocke les données conformément à sa politique de
              confidentialité disponible sur la Plateforme.
            </Typography>
            <Typography paddingTop={3} variant="h6">
              5.DURÉE ET RÉSILIATION
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              5.1.Durée du Contrat
            </Typography>
            <Typography variant="body1">
              Le Contrat entre en vigueur à la création du Compte et demeure en
              vigueur tant que l'Utilisateur utilise la Plateforme.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              5.2.Résiliation
            </Typography>
            <Typography variant="body1">
              ChoptonTicket se réserve le droit de résilier le Contrat en cas de
              violation des CGU par l'Utilisateur. L'Utilisateur peut résilier
              le Contrat en fermant son Compte conformément aux procédures
              définies sur la Plateforme.
            </Typography>
            <Typography paddingTop={3} variant="h6">
              6.RESPONSABILITÉS ET GARANTIES
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              6.1.Responsabilités de ChoptonTicket
            </Typography>
            <Typography variant="body1">
              ChoptonTicket s'engage à mettre en œuvre tous les moyens
              nécessaires pour assurer le bon fonctionnement de la Plateforme.
              Cependant, ChoptonTicket n'est pas responsable des interruptions
              de service résultant de problèmes techniques ou de tout autre
              événement indépendant de sa volonté.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              6.2.Responsabilités de l'Utilisateur
            </Typography>
            <Typography variant="body1">
              L'Utilisateur est seul responsable de l'utilisation de la
              Plateforme. Il garantit la véracité des informations fournies lors
              de la création du Compte et s'engage à respecter les dispositions
              légales en vigueur.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              6.3.Garanties
            </Typography>
            <Typography variant="body1">
              ChoptonTicket ne garantit pas que la Plateforme sera exempte
              d'erreurs ou que son utilisation sera ininterrompue. L'Utilisateur
              reconnaît utiliser la Plateforme à ses propres risques.
            </Typography>
            <Typography paddingTop={3} variant="h6">
              7.LES TRANSFERTS DE DONNÉES
            </Typography>
            <Typography variant="body1">
              ChoptonTicket se réserve le droit de modifier les CGU à tout
              moment. Les Utilisateurs seront informés des modifications via la
              Plateforme. L'utilisation continue de la Plateforme après la
              modification des CGU constitue une acceptation des nouvelles
              conditions.
            </Typography>
            <Typography paddingTop={3} variant="h6">
              8.LOI APPLICABLE ET JURIDICTION
            </Typography>
            <Typography variant="body1">
              Le Contrat est régi par les lois en vigueur en Côte d'Ivoire. En
              cas de litige, les parties conviennent de soumettre la résolution
              du litige aux tribunaux compétents de Côte d'Ivoire.
            </Typography>
            <Typography paddingTop={3} variant="h6">
              9.CONTACT
            </Typography>
            <Typography variant="body1">
              Pour toute question ou demande liée aux présentes CGU, veuillez
              contacter ChoptonTicket via les coordonnées fournies sur la
              Plateforme.
              <br />
              En acceptant les présentes CGU, l'Utilisateur reconnaît avoir lu,
              compris et accepté l'ensemble des dispositions du Contrat.
              <br />
              Fait à [Indiquer la ville], le [Indiquer la date].
              <br />
              [Signature électronique de ChoptonTicket, si applicable]
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              marginTop: 5,
              padding: 3,
              borderRadius: "10px",
              bgcolor: "black",
              color: "white",
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                align="left"
                sx={{
                  fontSize: "20px",
                  fontWeight: "800",
                }}
              >
                Il vous reste des questions ?
              </Typography>
              <Typography align="left">
                Notre équipe se fera un plaisir de vous aider
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  window.open("https://api.whatsapp.com/send?phone=962");
                }}
                size="large"
                sx={{
                  textTransform: "initial",
                  border: "2px solid transparent",
                  color: "black",
                  fontWeight: 600,
                  backgroundColor: "white",
                  borderRadius: "7px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    borderColor: "black",
                  },
                }}
                variant="contained"
              >
                Contactez-nous
              </Button>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Cgu;
