import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Footer from "../Components/Footer";
import { UserAuth } from "../context/AuthContext";
import Iconify from "../Components/Iconify";
import { useNavigate, useParams } from "react-router-dom";
import Ticket from "../Components/profil/ticket";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db, functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";

export default function Confirmation() {
  const { confirmID } = useParams();
  const [loading, setLoading] = React.useState();
  const [event, setEvent] = React.useState([]);
  const [eventID, setEventID] = React.useState([]);
  const [bill, setBill] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        // check
        const addMessage = httpsCallable(functions, "generateTicket");
        const result = await addMessage({ confirm: confirmID });
        console.log(result);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        const docRef = doc(db, "confirmations", `${confirmID}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log(docSnap.data());
          const refBillSnap = await getDoc(
            doc(db, "bills", docSnap.data().refBill)
          );
          if (refBillSnap.exists()) {
            console.log(refBillSnap.data());
            const eventSnap = await getDoc(
              doc(db, "evenement", refBillSnap.data().event)
            );
            setEventID(eventSnap.id);
            setEvent(eventSnap.data());
            setBill(refBillSnap.data());
          }
        }

        // console.log(docSnap.data());
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Container sx={{ paddingX: 5, paddingY: 15, color: "white" }}>
      <Box
        sx={{
          
          background: "linear-gradient(#292929, #999999)",
          position: "absolute",
          width: "100%",
          height: "150%",
          bottom: 0,
          left: 0,
          top: 0,
          zIndex: -1,
        }}
      />
      {loading ? (
        <Box
          sx={{ paddingY: 20 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack justifyContent='center' alignItems='center'>
            <CircularProgress sx={{ color: "white", width: 70, height: 70 }} />
            <Typography>Generation des tickets</Typography>
          </Stack>
          
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Iconify
              icon="el:ok-sign"
              sx={{
                width: 70,
                height: 70,
                alignItems: "center",
                color: "green",
              }}
            />
          </Box>

          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "800",
            }}
            align="center"
          >
            Félicitation, Ton billet est callé !!!
          </Typography>
          <Box paddingY={2} display="flex" justifyContent="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Card
                elevation={10}
                sx={{
                  width: { xs: "100%", sm: "70%" },
                  borderRadius: { xs: "7px", sm: "7px" },
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: "100%", objectFit: "cover" }}
                  height={{ xs: "375", sm: "425" }}
                  image={event["idVisuel"]}
                  title="green iguana"
                />
              </Card>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ width: { xs: "100%", sm: "60%" }, paddingTop: 5 }}
              justifyContent="center"
            >
              <Stack
                paddingY={2}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Iconify
                    icon="bi:ticket-perforated"
                    sx={{
                      width: 24,
                      height: 24,
                      alignItems: "center",
                    }}
                  />
                  <Typography fontSize={18}>
                    Télécharger votre billet
                  </Typography>
                </Stack>
                <Iconify
                  icon="material-symbols:download-rounded"
                  sx={{ width: 24, height: 24, alignItems: "center" }}
                />
              </Stack>
              <Divider />
              <Stack
                paddingY={2}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Iconify
                    icon="bi:send"
                    sx={{
                      width: 24,
                      height: 24,
                      alignItems: "center",
                    }}
                  />
                  <Typography fontSize={18}>
                    Ton reçu ({bill["totalPrice"]} CFA) et ton billet ont été
                    envoyés à bessechristian1@hotmail.fr
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack
                paddingY={2}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Iconify
                    icon="solar:help-outline"
                    sx={{
                      width: 24,
                      height: 24,
                      alignItems: "center",
                    }}
                  />
                  <Typography fontSize={18}>
                    Besoin d’aide ? Contactez-nous ou visite notre FAQ
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack
                paddingY={2}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Iconify
                    icon="ri:arrow-go-back-line"
                    sx={{
                      width: 24,
                      height: 24,
                      alignItems: "center",
                    }}
                  />
                  <Link
                    href={`http://localhost:3000/fr/event/${eventID}`}
                    underline="none"
                    color="white"
                  >
                    <Typography fontSize={18}>
                      Retour à la page de l'événement
                    </Typography>
                  </Link>
                </Stack>
              </Stack>
            </Box>
          </Box>

          <Card elevation={0} sx={{ bgcolor: "lightblue", marginY: 5 }}>
            <CardContent>
              <Typography fontSize={16}>
                La revente de billets est autorisée uniquement sur l'application
                Choptonticket avec son systeme de revente intégré et sécurisé.
              </Typography>
            </CardContent>
          </Card>
          <Stack direction="row" spacing={2} justifyContent='center'>
                <img
                  src={androidBadge}
                  alt="illustration"
                  style={{ maxWidth: 130 }}
                />
                <img style={{ Width: 150 }} src={iosBadge} alt="Logo" />
          </Stack>
        </>
      )}
    </Container>
  );
}
