import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  MobileStepper,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import House from "./EventCard";
import { properties } from "../properties";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";
import EventCard from "./EventCard";
import Iconify from "./Iconify";
import useResponsive from "./hooks/useResponsive";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import CustomCard from "./CustomCard";
import Footer from "./Footer";
import SubHero from "./subHero";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Article = () => {
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const [city, setCity] = React.useState("Abidjan");
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const today = new Date();
        setLoading(true);
        setEvents([]);

        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),

          where("city", "==", city),
          orderBy("dateDebut", "asc"),
          limit(3)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.empty()
        // console.log(querySnapshot);
        // setEvents(querySnapshot.docs);
        querySnapshot.forEach(async (d) => {
          // get event organisateur

          const querySnapshot1 = await getDoc(
            doc(db, "organisations", d.data().orgId)
          );

          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [city]);

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 3;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      
      <Box sx={{ pt: 20}}>
        <Container>
          <Stack>
            
              <Typography variant="h4">
                Politique de Données Personnlles
              </Typography>
                <Divider/>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              marginTop: 5,
              padding: 3,
              borderRadius: "10px",
              bgcolor: "black",
              color: "white",
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                align="left"
                sx={{
                  fontSize: "20px",
                  fontWeight: "800",
                }}
              >
                Il vous reste des questions ?
              </Typography>
              <Typography align="left">
                Notre équipe se fera un plaisir de vous aider
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  window.open("https://api.whatsapp.com/send?phone=962");
                }}
                size="large"
                sx={{
                  textTransform: "initial",
                  border: "2px solid transparent",
                  color: "black",
                  fontWeight: 600,
                  backgroundColor: "white",
                  borderRadius: "7px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    borderColor: "black",
                  },
                }}
                variant="contained"
              >
                Contactez-nous
              </Button>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Article;
