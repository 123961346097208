import {
  Box,
  Button,
  CardMedia,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Navbar from "./Navbar";

import heroImg from "../media/hero_illustration.png";
import CustomButton from "./CustomButton";

import Typewriter from "typewriter-effect";
import heroImage from "../media/hero.jpg";
import useResponsive from "./hooks/useResponsive";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";

const Hero = () => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
      textAlign: "center",
    gap: theme.spacing(5),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#000336",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  }));

  return (
    <Box>
      {/*<Navbar />*/}
      <Box sx={{ paddingY: { xs: 10, sm: 10 }, minHeight: "50vh" }}>
        <Container>
          <CustomBox>
            <Box
              sx={{
                bgcolor: "yellow",
                position: "absolute",
                width: "100%",
                left: 0,
                zIndex: -1,
                filter: { xs: "blur(5rem)", sm: "blur(10rem)" },
              }}
            >
              {/** */}
              <CardMedia
                component="img"
                sx={{ width: "100%", objectFit: "cover" , background: "linear-gradient(0.25turn, #e66465, #9198e5)"}}
                height="400"
                title="green iguana"
              />
            </Box>
            <Box sx={{ padding:5 }}>
              <Typography
                sx={{ color: "white", fontSize: "35px", fontWeight: "800" }}
              >
                Bonjour et Bienvenu sur CTT support
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "35px", fontWeight: "800" }}
              >
                Comment pouvons nous t'aider ?
              </Typography>
            </Box>
          </CustomBox>
        </Container>
      </Box>
    </Box>
  );
};

export default Hero;
