import { Skeleton, Typography } from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useCallback } from "react";
import { useMemo } from "react";

const events = [
  { nom: "dd", lat: 50, lng: 56 },
  { nom: "d2", lat: 20, lng: 56 },
  { nom: "d3", lat: 20, lng: 5659 },
  { nom: "d4", lat: 2590, lng: 56 },
  { nom: "d5", lat: 77, lng: 56 },
];

export default function Maps({centerC, hover, loading}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const center = useMemo(() => {
    if (centerC) {
      return centerC
    } else {
      return { lat: 5.3599517, lng: -4.0082563 }
    }
  }, [centerC]);
  if (!isLoaded) return <Skeleton variant="rounded" width={`100%`} height={250} />;
  return (
    <GoogleMap
      zoom={17}
      center={center}
      mapContainerClassName="map-container"
      mapContainerStyle={{height: 300}}
    >
      <Marker position={center} />
    </GoogleMap>
  );
}
