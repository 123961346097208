import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Footer from "../Components/Footer";
import { UserAuth } from "../context/AuthContext";
import Iconify from "../Components/Iconify";
import { useNavigate, useParams } from "react-router-dom";
import Ticket from "../Components/profil/ticket";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { Variables } from "../context/VariableContext";
import EventCard from "../Components/EventCard";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Organisateur() {
  const [loading, setLoading] = useState(false);
  const { openLogin, setOpenLogin } = Variables();
  const { orgID } = useParams();
  const [events, setEvents] = React.useState([]);
  const [pastEvents, setPastEvents] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [nom, setNom] = React.useState("");
  const [num, setNum] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [socialMedia, setSocialMedia] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const [isFollow, setIsFollow] = React.useState(false);
  const [infoUser, setInfoUser] = React.useState();
  const { user, logOut } = UserAuth();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createPastEventList = (doc) => {
    setPastEvents((events) => [...events, doc]); // ajouter a la fin
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (orgID) {
          const u1 = await getDoc(doc(db, "organisations", `${orgID}`));
          
          // setInfoUser(userRef);
          if (u1.exists()) {
            console.log(u1.data());
            setNom(u1.data().nomOrg);
            setNum(u1.data().contact);
            setLocation(u1.data().email);
            setWebsite(u1.data().urlPhoto);
          }
          const cc = await getDoc(
            doc(db, `organisations/${orgID}/coordonnee`, `${orgID}`)
          );
          if (cc.exists()) {
            setLocation(`${cc.data().city}, ${cc.data().country}`);
            setWebsite(u1.data().urlPhoto);
          }
          const q = query(
            collection(db, "evenement"),
            where("orgId", "==", orgID),
            where("status", "==", 1),
            orderBy("dateDebut", "asc")
          );
          const querySnapshot = await getDocs(q);
          // setEvents(querySnapshot.docs);
          querySnapshot.forEach((doc) => {
            // sort list
            const element = doc;
            if (element.data().status === 1) {
              createEventList(element);
            } else if (element.data().status === 2) {
              createPastEventList(element);
            }
          });
          if(user){
            const userRef = await getDoc(doc(db, "users", user.uid));
          if (userRef.exists()) {
            const exist = userRef
              .data()
              .followings.findIndex((element) => element === orgID);
            if (exist === -1) {
              setIsFollow(false);
            } else {
              setIsFollow(true);
            }
          }
          }
          
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh, user]);

  // follow
  const follow = async () => {
    if (user) {
      var exist;
      console.log(user.email);
      console.log(infoUser);
      setLoading(true);
      try {
        if (infoUser.data().followings) {
          exist = infoUser
            .data()
            .followings.findIndex((element) => element === orgID);
        } else {
          exist = -1;
        }

        if (exist === -1) {
          // abonnement
          await setDoc(
            doc(db, `organisations/${orgID}/crm/${orgID}/contacts`, user.email),
            {
              uid: user.uid,
              nom: infoUser.data().nom,
              prenom: infoUser.data().prenom,
              following: true,
            }
          );

          await updateDoc(doc(db, `users/${user.uid}`), {
            followings: arrayUnion(orgID),
          });
        } else {
          // desabonnement
          await setDoc(
            doc(db, `organisations/${orgID}/crm/${orgID}/contacts`, user.email),
            {
              uid: user.uid,
              nom: infoUser.data().nom,
              prenom: infoUser.data().prenom,
              following: false,
            }
          );

          await updateDoc(doc(db, `users/${user.uid}`), {
            followings: arrayRemove(orgID),
          });
        }

        // setConfirm(true);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      setRefresh(!refreh);
    } else {
      setOpenLogin(true);
    }
  };

  return (
    <>
      <Container sx={{ paddingX: { xs: 2, sm: 5 }, paddingY: 10 }}>
        <Stack spacing={1}>
          <Box paddingTop={5} display="flex" justifyContent="center">
            <Avatar sx={{ width: 125, height: 125 }}></Avatar>
          </Box>

          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "600",
            }}
            align="center"
          >
            {nom}
          </Typography>

          <Stack direction="row" justifyContent="center" spacing={2}>
            {num && (
              <Stack direction="row">
                <Iconify
                  icon="mdi:telephone-outline"
                  sx={{ width: 24, height: 24 }}
                />
                <Typography>{num}</Typography>
              </Stack>
            )}
            {email && num && (
              <Iconify icon="ci:line-l" sx={{ width: 24, height: 24 }} />
            )}

            {email && (
              <Stack direction="row">
                <Iconify
                  icon="ion:mail-outline"
                  sx={{ width: 24, height: 24 }}
                />
                <Typography>{email}</Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Iconify
              icon="humbleicons:location"
              sx={{ width: 24, height: 24 }}
            />
            <Typography>{location}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Iconify icon="carbon:link" sx={{ width: 24, height: 24 }} />
            <Typography>{website}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Iconify
              icon="entypo-social:facebook"
              sx={{ width: 24, height: 24 }}
            />
            <Iconify
              icon="teenyicons:instagram-solid"
              sx={{ width: 24, height: 24 }}
            />
            <Iconify icon="bi:twitter" sx={{ width: 24, height: 24 }} />
          </Stack>
        </Stack>
        <Box display="flex" justifyContent="center" paddingY={2}>
          {isFollow ? (
            <Button
              size="medium"
              sx={{
                textTransform: "initial",
                border: "2px solid #5E17EB",
                backgroundColor: "white",
                color: "#5E17EB",
                height: 40,
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#5E17EB",
                },
              }}
              variant="contained"
              onClick={() => {
                follow();
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Stack direction="row" display="flex" alignItems="center">
                  <Typography fontSize={14}>Abonner</Typography>{" "}
                  <Iconify
                    icon="flat-color-icons:ok"
                    sx={{ height: 20, width: 20 }}
                  />
                </Stack>
              )}
            </Button>
          ) : (
            <Button
              size="medium"
              sx={{
                textTransform: "initial",
                border: "2px solid #5E17EB",
                backgroundColor: "#5E17EB",
                color: "white",
                height: 40,
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "#5E17EB",
                  color: "white",
                },
              }}
              variant="contained"
              onClick={() => {
                follow();
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography fontSize={14}>S'abonner</Typography>
              )}
            </Button>
          )}
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: { xs: "100%" } }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Evenements à venir" {...a11yProps(0)} />
                <Tab label="Evenements passés" {...a11yProps(1)} />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                ></Box>
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {loading ? (
                <Grid container spacing={5} paddingY={2}>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container rowSpacing={5} paddingY={2}>
                  {events.map((property, index) => (
                    <Grid item xs={12} md={4} sx={{ height: `100%` }}>
                      <EventCard
                        key={property.id}
                        img={property.data().idVisuel}
                        name={property.data().nomEvent}
                        lieu={property.data().lieu_event}
                        city={property.data().city}
                        type={property.data().type}
                        debut={property.data().dateDebut}
                        organisateur={nom}
                        eventID={property.id}
                        sx={{ height: `100%` }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {loading ? (
                <Grid container spacing={5} paddingY={2}>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container rowSpacing={5} paddingY={2}>
                  {pastEvents.map((property, index) => (
                    <Grid item xs={12} md={4} sx={{ height: `100%` }}>
                      <EventCard
                        key={property.id}
                        img={property.data().idVisuel}
                        name={property.data().nomEvent}
                        lieu={property.data().lieu_event}
                        city={property.data().city}
                        type={property.data().type}
                        debut={property.data().dateDebut}
                        organisateur={nom}
                        eventID={property.id}
                        sx={{ height: `100%` }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </TabPanel>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
